* {
  color: #fff;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.player {
  position: fixed;
  bottom: -1px;
  left: 0;
  width: 100%;
  z-index: 10;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #0F0F29;
  text-align: center;
  padding: 10px;
}

.player h2 {
  font-size: .8rem;
  padding: 0px;
  margin: 0;
}

.player h3 {
  font-size: .5rem;
  padding: 0px;
  margin: 0;
}

.hero-button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.App {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-in-navbar {
  display: none;
}

.logo-container img {
  width: 40px;
  height: 40px;
}

.logo-container h1 {
  font-size: 1rem;
  margin-left: 10px;
}

.mobile-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.menu-button {
  z-index: 10;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
}

.drawer {
  transform: translateX(0px);
  transition: all 300ms cubic-bezier(1, 0, 0, 1) 0s;
  background-color: #0F0F29;
  min-height: 100%;
  z-index: 10;
}

.drawer {
  position: absolute;
  z-index: 2;
  width: 100%;
  max-width: 375px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
}

.drawer--hidden {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: 300ms cubic-bezier(1,0,0,1);
  transition: 300ms cubic-bezier(1,0,0,1);
}

.drawer-link {
  padding: 20px;
  position: relative;
  cursor: pointer;
}

/* A vertical bar before each item  */
.drawer-link--active::before, .drawer-link:hover::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #F77A55;
}

.hero {
  position: relative;
}

.hero img {
  width: 100%;
  height: 450px;
  max-height: 40vh;
  object-fit: cover;
}

.hero-info {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  display: flex;
  align-items: center;
  
}

.hero-text {
  padding-left: 20px;
}

.hero-text h2 {
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
}

.hero-text h3 {
  font-size: 1rem;
  padding: 0;
  margin: 0;
}

.main-content .text {
  padding: 35px;
}

.text-body {
  font-size: 1.1rem;
}

.sidebar {
  margin-bottom: 165px;
}

.active {
  color: #F77A55;
}

.section--hide {
  display: none;
}

.sidebar-content {
  display: flex;
  align-items: center;
  padding: 20px;
}

.sidebar-content img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.sidebar-content h2 {
  font-weight: 500;
  font-size: .9rem;
  padding: 0;
  margin: 0;
}

.sidebar-content h3 {
  font-weight: 400;
  font-size: .8rem;
  padding: 0;
  margin: 0;
}

.sidebar-text a {
  color: #F77A55;
}

@media screen and (min-width: 992px) {
  .App {
    flex-direction: row;
  }

  .mobile-menu {
    display: none;
  }

  .drawer {
    position: static;
    max-width: 300px;
    transform: none;
  }

  .logo-in-navbar {
    display: flex;
    margin-bottom: 40px;
  }

  .main-content {
    max-width: 710px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    min-height: 100vh;
    padding-bottom: 160px;
  }
}