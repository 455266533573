* {
  font-family: 'Poppins', sans-serif;
}

.AccessWall {
  font-family: 'Poppins', sans-serif;
}

#header {
  overflow: hidden;
  position: relative;
}

#header img {
  width: 100%;
  min-height: 500px;
  z-index: -1;
  filter: blur(5px);
  position: absolute;
  object-fit: cover;
}

.text-container {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 30px;
}
